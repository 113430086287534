import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import Instructions from "./instructions"
import {updateAtVideo, updateCurrentVideo, updateShowQuestion, updateAnswerIndex} from "../actions/global"
import YouTube from 'react-youtube'
import { Form, Field } from 'react-final-form'
import classnames from 'classnames'
import Sound from './sound'

const Video = ({ locale, match, t, showInstructionOverlay, updateAtVideo, atVideo, updateCurrentVideo, currentVideo, showQuestion, updateShowQuestion, updateAnswerIndex, history }) => {

    const [player, setPlayer] = useState(false)

    useEffect(() => {
        if (!atVideo) {
            updateAtVideo(true)
            updateShowQuestion(false)
        }

        if (!currentVideo || currentVideo > 10) {
            updateCurrentVideo(1)
        }

        return () => {
            window.clearTimeout(Video.timer)
        }
    })

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 1,
            showsearch: 0,
            rel: 0,
            showinfo: 0,
            controls: 0,
            modestbranding: 1,
            enablejsapi: 1,
            origin: window.location.origin.match(/localhost/i) ? 'https://signaleren.canvasaccept.com' : 'https://signaleren.alzheimer-nederland.nl'
        }
    }

    const onReady = (event) => {
        setPlayer(event.target)
    }

    const onPlayEvent = (event) => {
        let duration = event.target.getDuration()
        Video.timer = window.setInterval(() => {
            if (document.getElementById('timer')) {
                document.getElementById('timer').style.width = (event.target.getCurrentTime() / duration) * 100 + '%'
            }
        }, 100)

        updateShowQuestion(false)
    }

    const onEndEvent = (event) => {
        window.clearTimeout(Video.timer)
        document.getElementById('timer').style.width = 0

        updateShowQuestion(true)
    }

    const nextQuestion = values => {
        updateAnswerIndex(currentVideo-1, values.score);

        if (currentVideo === 10) {
            history.push(`/${locale}/resultaat`);
            return
        }

        updateCurrentVideo((currentVideo + 1))
        updateShowQuestion(false)
    }

    const restartVideo = () => {
        player.playVideo()
        updateShowQuestion(false)
    }

    return (
        <React.Fragment>
            <Helmet>
                <html lang={match.params.language} />
                <title>Alzheimer Nederland - SignaLeren - Filmpjes</title>
            </Helmet>

            <main id="video" className={classnames({
                rtl: locale === 'ar'
            })}>
                <YouTube
                    videoId={t(`videos.${process.env.NODE_ENV}.video${currentVideo}`)}
                    containerClassName="video"
                    id={'video' + currentVideo}
                    opts={opts}
                    onReady={onReady}
                    onPlay={onPlayEvent}
                    onEnd={onEndEvent}
                />
                {showQuestion &&
                    <div id="question">
                        <Form
                            onSubmit={nextQuestion}
                            render={({ handleSubmit }) => (
                                <form className="question" onSubmit={handleSubmit}>
                                    <div className="question__top">
                                        <h3>
                                            <Sound label={t('video-page.question')} data={'4s'} />
                                        </h3>
                                    </div>
                                    <div className="question__content">
                                        <div className="question__content__option">
                                            <p><Sound label={t('video-page.not-familiar')} data={'4n'} /></p>
                                        </div>
                                        <div className="question__content__slider">
                                            <fieldset>
                                                <Field initialValue={0} name="score" component="input" type="range"  min="0" max="100" parse={v => parseInt(v)} />
                                                <p>{t('video-page.slider')} {t('general.continue')}.</p>
                                            </fieldset>
                                        </div>
                                        <div className="question__content__option option_right">
                                            <p><Sound label={t('video-page.familiar')} data={'4h'} /></p>
                                        </div>
                                    </div>
                                    <div className="question__bottom">
                                        <button className="btn secondary" onClick={() => restartVideo() }><Sound label={t('video-page.watch-again')} data={'4o'} /></button>
                                        <button className="btn" type="submit"><Sound label={t('general.continue')} data={'2g'} /></button>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                }
                <span id="timer" />
                <nav className="videos">
                    {Array(10).fill(undefined).map((_,index) => (
                        <div
                            key={index}
                            className={classNames({
                                active: currentVideo === (index+1)
                            })}
                            style={{ backgroundImage: `url(https://img.youtube.com/vi/${t(`videos.${process.env.NODE_ENV}.video${index+1}`)}/mqdefault.jpg` }}
                        />
                    ))}
                </nav>

                {showInstructionOverlay &&
                    <Instructions />
                }
            </main>

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    locale: state.global.locale,
    currentVideo: state.global.currentVideo,
    atVideo: state.global.atVideo,
    showQuestion: state.global.showQuestion,
    showInstructionOverlay: state.global.showInstructionOverlay
})

const mapDispatchToProps = dispatch => ({
    updateAtVideo: value => dispatch(updateAtVideo(value)),
    updateCurrentVideo: value => dispatch(updateCurrentVideo(value)),
    updateShowQuestion: value => dispatch(updateShowQuestion(value)),
    updateAnswerIndex: (index, value) => dispatch(updateAnswerIndex(index, value))
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Video)))
