import React from 'react'
import { connect } from 'react-redux'
import Logo from './logo.js'
import Audio from './audio.js'
import Help from './help.js'

const Header = () => {
    return (
        <header>
            <article>
                <Logo />

                <div className="header_nav">
                    <Help />
                    <Audio />
                </div>
            </article>
        </header>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)