import 'core-js/stable'
import 'isomorphic-fetch'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Store from './store'
import MainRouter from './main-router'

import './i18n'
import './assets/scss/app.scss'

ReactDOM.render(
    <Provider store={Store}>
        <MainRouter />
    </Provider>,
    document.getElementById('root')
)