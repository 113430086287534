import React from 'react'
import { connect } from 'react-redux'

const Footer = ({ locale }) => {
    return (
        <footer>
            <article>
                <nav>
                    <a href="https://www.alzheimer-nederland.nl/privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
                    <a href="https://www.alzheimer-nederland.nl/cookies" target="_blank" rel="noopener noreferrer">Cookie-instellingen</a>
                </nav>
            </article>
        </footer>
    )
}

const mapStateToProps = state => ({
    locale: state.global.locale
})

export default connect(mapStateToProps, null)(Footer)