import React from 'react'
import { NavLink } from 'react-router-dom'
import LogoSVG from '../assets/img/logo.svg'
import { connect } from 'react-redux'
import {updateShowInstructionOverlay} from "../actions/global";

const Logo = ({ updateShowInstructionOverlay }) => {

    return (
        <div className="logo">
            <NavLink to={'/start'} onClick={() => updateShowInstructionOverlay(false) }>
                <img src={LogoSVG} alt="Alzheimer Nederland" />
            </NavLink>
        </div>
    )

}


const mapDispatchToProps = dispatch => ({
    updateShowInstructionOverlay: value => dispatch(updateShowInstructionOverlay(value))
})



export default connect(null, mapDispatchToProps)(Logo)
