import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Home from './components/home-page'
import Video from './components/video-page'
import Result from './components/result-page'
import PageWrapper from './components/page'

const MainRouter = ({ locale }) => {

    const { i18n } = useTranslation('', {useSuspense: false})

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [i18n, locale])

    return (
        <Router>
            <PageWrapper>
                <Route path="/email" target="_self" />
                <Route path="/:language" exact render={() => <Home />} />
                <Route path="/:language/filmpjes" exact component={Video} />
                <Route path="/:language/resultaat" exact component={Result} />
                <Route path="/" exact render={() => <Home />} />
            </PageWrapper>
        </Router>
    )
}

const mapStateToProps = state => ({
    locale: state.global.locale,
})

export default connect(mapStateToProps)(MainRouter)