import React, { Fragment } from 'react'
import Header from './header'
import Footer from './footer'
import { withRouter } from 'react-router'

const PageWrapper = ({children}) => (
    <Fragment>
        <Header/>

        {children}

        <Footer />
    </Fragment>
)

export default withRouter(PageWrapper)