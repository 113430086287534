import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationNL from './assets/translations/nl.json'
import translationAR from './assets/translations/ar.json'
import translationTR from './assets/translations/tr.json'
import translationBE from './assets/translations/be.json'

const resources = {
    nl: translationNL,
    ar: translationAR,
    tr: translationTR,
    be: translationBE
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'nl',
        interpolation: {
            escapeValue: false
        }
    })

export default i18n
