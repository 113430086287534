import fetch from 'cross-fetch'

export const createPostRequest = (address, headers = {}, body) => async () => {
    if (body instanceof URLSearchParams) {
        headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    } else {
        headers['Content-Type'] = 'application/json'
    }

    let url = `${address}`

    let response = await fetch(url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: body
    })

    return response
}