import React  from 'react'
import { connect } from 'react-redux'
import {useTranslation} from "react-i18next";
import {updateAudio, updateShowInstructionOverlay, updateCurrentVideo } from "../actions/global";
import {NavLink} from "react-router-dom";
import Sound from "./sound";

const Instructions = ({ isStarted, onContinue, locale, hasSelectedLocale, updateShowInstructionOverlay, atVideo, updateCurrentVideo }) => {

    const { t } = useTranslation()

    return (
        <div className="overlay__container">
            <NavLink to={'/'} className="close" onClick={(e) => { e.preventDefault(); updateShowInstructionOverlay(false) }}><span /><span /></NavLink>
            <h1>{t('instruction.title')}</h1>
            <p>
                <Sound label={t('instruction.description')} data={'2i'} html={true} />
            </p>
            {!atVideo &&
                <p>
                    {hasSelectedLocale ? (
                        <NavLink to={`/${locale}/filmpjes`} className="btn" onClick={(e) => { updateCurrentVideo(1); updateShowInstructionOverlay(false) }}>
                            <Sound label={t('general.continue')} data={'2g'} />
                        </NavLink>
                    ) : (
                        <button  
                            className="btn" 
                            onClick={() => {
                                updateShowInstructionOverlay(false) 
                            }}
                        >
                            <Sound label={t('general.continue')} data={'2g'} />
                        </button>
                    )}
                </p>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    locale: state.global.locale,
    atVideo: state.global.atVideo,
    hasSelectedLocale: state.global.hasSelectedLocale
})

const mapDispatchToProps = dispatch => ({
    updateAudio: value => dispatch(updateAudio(value)),
    updateCurrentVideo: value => dispatch(updateCurrentVideo(value)),
    updateShowInstructionOverlay: value => dispatch(updateShowInstructionOverlay(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Instructions)
