import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import Helmet from 'react-helmet'
import { EmailInput, TextInput, SubmitInput, RadioInput } from './../components/inputs'
import classnames from 'classnames'
import Sound from "./sound";
import {sendEmail, resetScores} from "../actions/global";
import shareTwitter from '../assets/img/twitter.svg'
import shareFacebook from '../assets/img/facebook.svg'
import shareLink from '../assets/img/link.svg'

const Results = ({ match, locale, t, scores, resetScores, history, sendEmail }) => {

    const [showForm, setShowForm] = useState(false)
    const [showSupport, setShowSupport] = useState(false)
    const [showThanks, setShowThanks] = useState(false)
    const [startDownload, setStartDownload] = useState(false)

    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const validate = (values) => {
        const errors = {}

        if (!values.gender) {
            errors.gender = t('results.required')
        }

        if (!values.last_name) {
            errors.last_name = t('results.required')
        }

        if (!values.email) {
            errors.email = t('results.required')
        } else {
            if (!emailRegex.test(String(values.email).toLowerCase())) {
                errors.email = t('results.incorrect_email')
            }
        }

        return errors
    }

    const onSubmit = values => {
        // Todo: Sent email
        let response = sendEmail(values)
        console.log('passed email send')
        console.log(response)

        setShowSupport(true)
        setStartDownload(true)

        downloadBrochure()
    }

    const downloadBrochure = () => {
        let lang = 'nl'
        if (locale === 'tr') lang = 'tr'
        window.open('https://signaleren.alzheimer-nederland.nl/docs/brochure.php?lang=' + lang)
    }

    const restartTest = () => {
        setShowThanks(false)
        setShowSupport(false)
        setStartDownload(false)
        resetScores(true)
        history.push('/')
    }

    const shareOn = (platform) => {
        if (platform === 'twitter' ) {
            window.open('https://twitter.com/home?status=SignaLeren - Grenzeloos dementie herkennen. Vermoed u geheugenproblemen bij iemand in uw omgeving? Probeer samen de test en krijg meer inzicht in uw situatie. SignaLeren is een initiatief van Alzheimer Nederland. https://signaleren.alzheimer-nederland.nl')
        }

        if (platform === 'facebook') {
            window.open('https://www.facebook.com/sharer/sharer.php?u=https://signaleren.alzheimer-nederland.nl')
        }

        if (platform === 'copy') {
            let copyUrl = document.getElementById('copy-url')
                copyUrl.select()
                copyUrl.setSelectionRange(0, 1000)
                document.execCommand('copy')
        }
    }

    let totalScore = 0
    if (scores) {
        for (let i = 0; i < scores.length; i++) {
            totalScore += scores[i]
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <html lang={match.params.language} />
            </Helmet>

            <main className={classnames({
                rtl: locale === 'ar'
            })}>
                <div className="result">

                    {showSupport || showThanks ?
                        <React.Fragment>
                            {showSupport &&
                            <div className="result_copy">
                                <h1>{t('results.support.title')}</h1>
                                <p>
                                    {startDownload &&
                                        <span onClick={() => downloadBrochure()} dangerouslySetInnerHTML={{ __html: t('results.support.download') + ' '}} />
                                    }
                                    <Sound label={t('results.support.description')} data={'6a1o'}/>
                                </p>

                                <p>{t('results.support.how')}</p>

                                <p>{t('results.support.how_description')}</p>

                                <span className="btn" onClick={(e) => {
                                    e.preventDefault();
                                    setShowThanks(true)
                                    setShowSupport(false)
                                }}>{t('general.continue')}</span>
                            </div>
                            }
                            {showThanks &&
                                <div className="result_copy">
                                    <h1><Sound label={t('results.thanks.description')} data={'6.a2b'}/></h1>
                                    <p>{t('results.thanks.title')}</p>

                                    <nav className="result_share">
                                        <img src={shareTwitter} className="result-share-icon" onClick={() => shareOn('twitter')} alt="Deel via Twitter" />
                                        <img src={shareFacebook} className="result-share-icon" onClick={() => shareOn('facebook')} alt="Deel via Facebook" />
                                        <img src={shareLink} className="result-share-icon" onClick={() => shareOn('copy')} alt="Website link kopiëren" />
                                        <input type="text" id="copy-url" defaultValue="https://signaleren.alzheimer-nederland.nl" />
                                    </nav>

                                    <span className="btn" onClick={(e) => {
                                        e.preventDefault();
                                        restartTest()
                                    }}><Sound label={t('results.thanks.restart')} data={'2b'}/></span>
                                </div>
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {!showForm ?
                                <div className="result_copy">
                                    <h1>{t('results.score.title')}</h1>
                                    {totalScore < 25 ?
                                        <p><Sound label={t('results.score.low')} data={'5r'} /></p>
                                    :
                                        <React.Fragment>
                                            {totalScore < 60 ?
                                                <p><Sound label={t('results.score.medium')} data={'2r'} /></p>
                                            :
                                                <p><Sound label={t('results.score.high')} data={'3r'} /></p>
                                            }
                                        </React.Fragment>
                                    }

                                    <p><Sound label={t('results.score.more_info')} data={'6i'} /></p>

                                    <span className="btn" onClick={(e) => {
                                    e.preventDefault();
                                    setShowForm(true)
                                }}><Sound label={t('results.score.yes')} data={'6j'} /></span>
                                    <span className="btn secondary" onClick={(e) => {
                                    e.preventDefault();
                                    setShowSupport(true)
                                }}><Sound label={t('results.score.no')} data={'6n'} /></span>
                                </div>
                            :
                                <Form
                                    onSubmit={onSubmit}
                                    validate={validate}
                                    initialValues={
                                    {
                                        last_name: '',
                                        email: '',
                                        submit: t('general.continue')
                                    }
                                }
                                    render={({handleSubmit, submitting, form}) => (
                                    <form onSubmit={event => {
                                        const promise = handleSubmit(event);
                                        promise && promise.then((value) => {
                                            if (value === true) {
                                                form.reset();
                                            }
                                        })
                                        return promise
                                    }}>
                                        <fieldset>
                                            <div className="row">
                                                <Field
                                                    values={[
                                                        {title: 'De heer', data: 'm'},
                                                        {title: 'Mevrouw', data: 'f'}
                                                    ]}
                                                    name="gender"
                                                    id="gender"
                                                    component={RadioInput}
                                                />
                                                <div className="c"/>
                                            </div>
                                            <div id="row_last_name" className="row">
                                                <Field label={t('results.last-name') + ' *'} component={TextInput}
                                                       name="last_name" id="last_name"/>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <div className="row">
                                                <Field label={t('results.email') + ' *'} component={EmailInput} name="email"
                                                       id="email"/>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <div className="row">
                                                <Field id="checkbox_help_prevent" component="input" type="checkbox"
                                                       name="help_prevent"/>
                                                <label htmlFor="checkbox_help_prevent"
                                                       className="inline">{t('results.help_prevent')}</label>
                                                <div className="c"/>
                                            </div>
                                            <div className="row">
                                                <Field id="checkbox_newsletter" component="input" type="checkbox"
                                                       name="newsletter"/>
                                                <label htmlFor="checkbox_newsletter"
                                                       className="inline">{t('results.newsletter')}</label>
                                                <div className="c"/>
                                            </div>
                                        </fieldset>
                                        <Field
                                            name="submit"
                                            id="submit"
                                            component={SubmitInput}
                                            disabled={submitting}
                                            classes={['btn']}
                                        />
                                    </form>
                                )}
                            />
                        }
                        </React.Fragment>
                    }
                </div>
            </main>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    locale: state.global.locale,
    scores: state.global.scores
})

const mapDispatchToProps = dispatch => ({
    sendEmail: (values) => dispatch(sendEmail(values)),
    resetScores: () => dispatch(resetScores()),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Results)))
