const initialState = {
    locale: 'nl',
    hasSelectedLocale: false,
    showInstructionOverlay: false,
    audio: true,
    atVideo: false,
    currentVideo: 1,
    showQuestion: false,
    scores: Array(10).fill(0)
}

export const global = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_LOCALE':
            return {
                ...state,
                locale: action.value
            }
        case 'UPDATE_SHOW_INSTRUCTION_OVERLAY':
            return {
                ...state,
                showInstructionOverlay: action.value
            }
        case 'UPDATE_AUDIO':
            return {
                ...state,
                audio: action.value
            }
        case 'UPDATE_AT_VIDEO':
            return {
                ...state,
                atVideo: action.value
            }
        case 'UPDATE_CURRENT_VIDEO':
            return {
                ...state,
                currentVideo: action.value
            }
        case 'UPDATE_SHOW_QUESTION':
            return {
                ...state,
                showQuestion: action.value
            }
        case 'RESET_SCORES':
            return {
                ...state,
                scores: Array(10).fill(0)
            }
        case 'UPDATE_ANSWER_INDEX':
            return {
                ...state,
                scores: state.scores.map((v, i) => { // Only change the value of the index
                    if (i !== action.index) return v
                    return action.value
                })
            }
        case 'UPDATE_HAS_SELECTED_LOCALE':
            return {
                ...state,
                hasSelectedLocale: action.value
            }
        default:
            return state
    }
}