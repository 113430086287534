import React from 'react'
import IconSound from '../assets/img/audio.svg'
import IconSoundOff from '../assets/img/audio_off.svg'
import { connect } from 'react-redux'
import {useTranslation} from "react-i18next"
import {updateAudio} from "../actions/global"

const Audio = ({ audio, updateAudio }) => {

    const { t } = useTranslation()

    return (
        <div className="audio" onClick={() => updateAudio(!audio)}>
            <img src={audio ? IconSound : IconSoundOff} alt="Geluid aan/uit" />
            <p>
                {audio ? t('header.audio') : t('header.audio_off')}
            </p>
        </div>
    )

}


const mapStateToProps = state => ({
    audio: state.global.audio
})

const mapDispatchToProps = dispatch => ({
    updateAudio: value => dispatch(updateAudio(value))
})


export default connect(mapStateToProps, mapDispatchToProps)(Audio)
