import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { updateLocale, updateShowInstructionOverlay, updateAtVideo, resetScores } from '../actions/global'
import Helmet from 'react-helmet'
import Start from './start.js'
import Instructions from "./instructions";
import classnames from 'classnames'

const Home = ({ match, locale, showInstructionOverlay, updateShowInstructionOverlay, t, updateAtVideo, resetScores }) => {

    useEffect(() => {
        updateAtVideo(false)
        resetScores()
    })

    return (
        <React.Fragment>
            <Helmet>
                <html lang={match.params.language} />
            </Helmet>

            <main id="homepage" className={classnames({
                rtl: locale === 'ar'
            })}>
                <Start />

                {showInstructionOverlay &&
                    <Instructions />
                }
            </main>

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    showInstructionOverlay: state.global.showInstructionOverlay,
    locale: state.global.locale
})

const mapDispatchToProps = dispatch => ({
    updateLocale: value => dispatch(updateLocale(value)),
    updateAtVideo: value => dispatch(updateAtVideo(value)),
    resetScores: () => dispatch(resetScores()),
    updateShowInstructionOverlay: value => dispatch(updateShowInstructionOverlay(value))
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Home)))
