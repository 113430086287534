import React from 'react'
import { connect } from 'react-redux'
import {useTranslation} from "react-i18next"
import { updateShowInstructionOverlay } from '../actions/global'

const Help = ({ showInstructionOverlay, updateShowInstructionOverlay }) => {

    const { t } = useTranslation()

    return (
        <div className="help" onClick={() => updateShowInstructionOverlay(!showInstructionOverlay)}>
            <h6>?</h6>
            <p>
                {showInstructionOverlay ? t('header.help_close') : t('header.help')}
            </p>
        </div>
    )

}

const mapStateToProps = state => ({
    showInstructionOverlay: state.global.showInstructionOverlay
})

const mapDispatchToProps = dispatch => ({
    updateShowInstructionOverlay: value => dispatch(updateShowInstructionOverlay(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Help)
