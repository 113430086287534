import { createPostRequest } from '../helpers/api'

export const sendEmail = (values) => async dispatch => {
    return new Promise(async (resolve, reject) => {

        let requestDataObject = {
            aanhef: values.gender,
            achternaam: values.last_name,
            email: values.email,
            helpen: values.help_prevent ? 1 : 0,
            nieuwsbrief: values.newsletter ? 1 : 0,
        }

        let formData = new URLSearchParams(requestDataObject)

        let response = await dispatch(createPostRequest('https://signaleren.canvasaccept.com/email/send_it.php', [], formData))
        // let response = await dispatch(createPostRequest(process.env.BASE_URL + '/email/send_it.php', [], formData))

        const payload = await response.json()

        const {status} = payload
        if (status === 'success') {

        }

        resolve(true)
    })
}

export const updateLocale = value => ({
    type: 'UPDATE_LOCALE',
    value
})

export const updateShowInstructionOverlay = value => ({
    type: 'UPDATE_SHOW_INSTRUCTION_OVERLAY',
    value
})

export const updateAudio = value => ({
    type: 'UPDATE_AUDIO',
    value
})

export const updateAtVideo = value => ({
    type: 'UPDATE_AT_VIDEO',
    value
})

export const updateCurrentVideo = value => ({
    type: 'UPDATE_CURRENT_VIDEO',
    value
})

export const updateShowQuestion = value => ({
    type: 'UPDATE_SHOW_QUESTION',
    value
})

export const updateAnswerIndex = (index, value) => ({
    type: 'UPDATE_ANSWER_INDEX',
    index,
    value
})

export const resetScores = () => ({
    type: 'RESET_SCORES'
})

export const updateHasSelectedLocale = value => ({
    type: 'UPDATE_HAS_SELECTED_LOCALE',
    value
})