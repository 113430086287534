import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {updateLocale, updateShowInstructionOverlay, updateHasSelectedLocale } from "../actions/global"
import classnames from 'classnames'
import ChooseBerbers from '../assets/img/kies_berbers.png'
import Sound from "./sound";

const Start = ({ locale, history, location, updateLocale, updateShowInstructionOverlay, updateHasSelectedLocale, audio }) => {

    const { i18n } = useTranslation('', {useSuspense: false})

    const [start, setStart] = useState(false)

    const { t } = useTranslation()

    const toVideo = (language) => {
        i18n.changeLanguage(
            language,
            () => {
                updateLocale(language)
                updateShowInstructionOverlay(true)
            }
        )
        updateShowInstructionOverlay(true)
        updateHasSelectedLocale(true)
    }

    return (
        <section id="start">
            <h1><Sound label={t('start.title')} data={'1s'}/></h1>
            <h3><Sound label={t('start.sub_title')} data={'1g'}/></h3>

            {start ?
                <nav id="choose_language" className={classnames({
                    'with-audio': audio
                })}>
                    <a href="#nl" onClick={(e) => {
                        e.preventDefault();
                        toVideo('nl')
                    }}>
                        <Sound 
                            data={'1t'}
                            html={true}
                            overrideLocale="nl"
                            label={'<span>Kies uw taal</span><em>Nederlands</em>'} 
                        />
                        {/* <audio id="a_lang_nl" src={`/audio/nl/1t.mp3`} muted={!audio}/> */}
                    </a>
                    {/*<a href="#be" onClick={(e) => {*/}
                        {/*e.preventDefault();*/}
                        {/*toVideo('be')*/}
                    {/*}}>*/}
                        {/*<Sound */}
                            {/*data={'1t'}*/}
                            {/*html={true}*/}
                            {/*overrideLocale="be"*/}
                            {/*label={`<img src="${ChooseBerbers}" alt="Berbers"/><em>Berbers</em>`} */}
                        {/*/>*/}
                        {/*/!* <audio id="a_lang_be" src={`/audio/be/1t.mp3`} muted={!audio}/> *!/*/}
                    {/*</a>*/}
                    <a href="#tr" onClick={(e) => {
                        e.preventDefault();
                        toVideo('tr')
                    }}>
                        <Sound 
                            data={'1t'}
                            html={true}
                            overrideLocale="tr"
                            label="<span>dilinizi seçin</span><em>Turks</em>"
                        />
                        {/* <audio id="a_lang_tr" src={`/audio/tr/1t.mp3`} muted={!audio}/> */}
                    </a>
                    <a href="#ar" onClick={(e) => {
                        e.preventDefault();
                        toVideo('ar')
                    }}>
                        <Sound 
                            data={'1t'}
                            html={true}
                            overrideLocale="ar"
                            label="<span>إختر لغتك</span><em>Arabisch</em>"
                        />
                        {/* <audio id="a_lang_ar" src={`/audio/ar/1t.mp3`} muted={!audio}/> */}
                    </a>
                </nav>
                :
                <div className="start-actions">
                    <a href="#start" className="btn" onClick={(e) => { e.preventDefault(); setStart(true); }}>Start</a>
                </div>
            }
        </section>
    )

}


const mapStateToProps = state => ({
    locale: state.global.locale,
    audio: state.global.audio

})


const mapDispatchToProps = dispatch => ({
    updateLocale: value => dispatch(updateLocale(value)),
    updateShowInstructionOverlay: value => dispatch(updateShowInstructionOverlay(value)),
    updateHasSelectedLocale: value => dispatch(updateHasSelectedLocale(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Start))
