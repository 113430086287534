import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

const Sound = ({ label, data, locale, overrideLocale, audio, html = false, onClick }) => {
    const audioElement = useRef(null)
    const [playPromise, setPlayPromise] = useState(null)

    const playSound = () => {
        if (playPromise) return

        setPlayPromise(audioElement.current.play())
    }

    const stopSound = async () => {

        if (playPromise) {
            await audioElement.playPromise
            setPlayPromise(null)
        }

        audioElement.current.pause()
        audioElement.current.currentTime = 0
    }

    return (
        <React.Fragment>
            <span className="sound"
                onMouseEnter={playSound}
                onMouseLeave={stopSound}
                onClick={onClick}
            >
                {!html ?
                    <span>{label}</span>
                    :
                    <span  className={classnames({
                        block: data === '2i'
                    })} dangerouslySetInnerHTML={{ __html: label }} />
                }

                <audio 
                    ref={audioElement}
                    preload="auto"
                    src={`/audio/${overrideLocale ?? locale}/${data}.mp3`} 
                    muted={!audio} 
                />
            </span>
        </React.Fragment>
    )

}


const mapStateToProps = state => ({
    locale: state.global.locale,
    audio: state.global.audio
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Sound)
