import React, { useState } from 'react'
import classnames from 'classnames'
import classNames from 'classnames'

const TextInput = ({ input, tabIndex = 0, label, disabled, type, placeholder, focus, classes, onChange, max = false, hidden, meta, id }) => {

    return (
        <div className={classnames('row', {
            'error': (meta.error || meta.submitError) && meta.touched,
            'large': type === 'textarea',
            'hidden': hidden
        })}>
            {label &&
            <label htmlFor={id}>{label}</label>
            }
            <input
                {...input}
                id={id}
                tabIndex={tabIndex}
                placeholder={placeholder}
                disabled={disabled}
                type="text"
                autoFocus={focus}
                maxLength={max ? max : 'auto'}
                className={classnames(classes)}
                autoComplete="off"
                onChange={(e) => {
                    input.onChange(e); //final-form's onChange
                    if (onChange) { //props.onChange
                        onChange(e);
                    }
                }}/>
            {max &&
            <p className="max-chars">Max {max} characters</p>
            }
            {(meta.error || meta.submitError) && meta.touched && (
                <p className={"error"}>{meta.error || meta.submitError}</p>
            )}
        </div>
    )
}

const EmailInput = ({ input, label, disabled, type, placeholder, focus, classes, onChange, hidden, meta, id }) => {

    return (
        <div className={classnames('row', {
            'error': (meta.error || meta.submitError) && meta.touched,
            'large': type === 'textarea',
            'hidden': hidden
        })}>
            {label &&
            <label htmlFor={id}>{label}</label>
            }
            <input
                {...input}
                id={id}
                placeholder={placeholder}
                disabled={disabled}
                type="email"
                autoFocus={focus}
                className={classnames(classes)}
                autoComplete="off"
                onChange={(e) => {
                    input.onChange(e); //final-form's onChange
                    if (onChange) { //props.onChange
                        onChange(e);
                    }
                }}/>
            {(meta.error || meta.submitError) && meta.touched && (
                <p className={"error"}>{meta.error || meta.submitError}</p>
            )}
        </div>
    )
}

const RadioInput = ({ input, values = [], onChange, classes = {}, label, meta }) => {

    const [ selectedRadio, setSelectedRadio ] = useState(null)

    return (
        <div className={classNames({'radio-container' : true, ...classes})}>
            {label &&
            <label>{label}</label>
            }
            <div className="radio">
                {values.map((value, i) => (
                    <label
                        key={i}
                        className={classNames({
                            'active': selectedRadio === null ? false : value.data === selectedRadio.data
                        })}
                    >
                        <div className="circle"><em /></div>
                        <input
                            type="radio"
                            name={input.name}
                            onClick={() => {
                                setSelectedRadio(value)
                                input.onChange(value.data)
                                if (onChange) onChange(value.data)
                            }}
                        />
                        <span>{value.title}</span>
                    </label>
                ))}
            </div>
            {(meta.error || meta.submitError) && meta.touched && (
                <p className={"error"}>{meta.error || meta.submitError}</p>
            )}
        </div>)
}


const SubmitInput = ({ input, id, label, reset = null, disabled, center = false, onChange, type, placeholder, select, value, focus, classes, options, hidden, meta }) => (
    <div className={classnames('row', {
        'error': (meta.error || meta.submitError) && meta.touched ,
        'large': type === 'textarea',
        'hidden': hidden,
    })} style={center ? {textAlign: 'center'} : {}}>
        {label &&
        <label htmlFor={id}>{label}</label>
        }
        <input
            {...input}
            id={id}
            disabled={disabled}
            type="submit"
            className={classnames(classes)}
            onClick={(e) => {
                if (onChange) {
                    onChange(e)
                }
            }}
            onChange={(e) => {
                input.onChange(e); //final-form's onChange
                if (onChange) { //props.onChange
                    onChange(e);
                }
            }}
        />
        {reset !== null &&
        <input
            type="reset"
            className="btn red margin-left"
            onClick={reset}
            value={"Reset"}
        />
        }
        {(meta.error || meta.submitError) && meta.touched && (
            <p className={"error"}>{meta.error || meta.submitError}</p>
        )}
    </div>
)

export { TextInput, SubmitInput, EmailInput, RadioInput }
